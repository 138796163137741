<template>
  <div>
    <div class="row">

      <div class="col-md-3">
        <b-overlay
          :show="OverLayCard.Card1"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="card p-2 rounded-10">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 text-secondary">
                แจ้งเตือนวันนี้
              </h4>

              <i
                class="far fa-redo colr-green"
                @click="GetCount('Level', null, 'Card1')"
              />
            </div>

            <p class="text-sz colr-green">
              {{ Commas(CardHeader.Notify.today) }}
            </p>
            <p class="text-center mt-1 colr-green">
              ครั้ง
            </p>

            <p class="text-center mt-50 mb-0 text-secondary">
              ทั้งหมด {{ Commas(CardHeader.Notify.all) }} ครั้ง
            </p>
          </div>
        </b-overlay>
      </div>

      <div class="col-md-3">
        <b-overlay
          :show="OverLayCard.Card2"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="card p-2 rounded-10">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 text-secondary">
                ประวัติการแจ้งความดำเนินคดีวันนี้
              </h4>

              <i
                class="far fa-redo colr-red"
                @click="GetCount('Level',2, 'Card2')"
              />
            </div>

            <p class="text-sz colr-red">
              {{ Commas(CardHeader.NotifyLeve2.today) }}
            </p>
            <p class="text-center mt-1 colr-red">
              ครั้ง
            </p>

            <p class="text-center mt-50 mb-0 text-secondary">
              ทั้งหมด {{ Commas(CardHeader.NotifyLeve2.all) }} ครั้ง
            </p>
          </div>
        </b-overlay>
      </div>

      <div class="col-md-3">
        <b-overlay
          :show="OverLayCard.Card3"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="card p-2 rounded-10">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 text-secondary">
                จำนวนเบอร์โทรศัพท์วันนี้
              </h4>

              <i
                class="far fa-redo colr-blue"
                @click="GetCount('Phone', null, 'Card3')"
              />
            </div>

            <p class="text-sz colr-blue">
              {{ Commas(CardHeader.Phone.today) }}
            </p>
            <p class="text-center mt-1 colr-blue">
              เบอร์
            </p>

            <p class="text-center mt-50 mb-0 text-secondary">
              ทั้งหมด {{ Commas(CardHeader.Phone.all) }} เบอร์
            </p>
          </div>
        </b-overlay>
      </div>

      <div class="col-md-3">
        <b-overlay
          :show="OverLayCard.Card4"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="card p-2 rounded-10">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 text-secondary">
                จำนวนบัญชีธนาคารวันนี้
              </h4>

              <i
                class="far fa-redo colr-orange"
                @click="GetCount('Bank', null, 'Card4')"
              />
            </div>

            <p class="text-sz colr-orange">
              {{ Commas(CardHeader.Bank.today) }}
            </p>
            <p class="text-center mt-1 colr-orange">
              บัญชี
            </p>

            <p class="text-center mt-50 mb-0 text-secondary">
              ทั้งหมด {{ Commas(CardHeader.Bank.all) }} บัญชี
            </p>
          </div>
        </b-overlay>
      </div>

      <div class="col-md-12">
        <b-overlay
          :show="OverLayCard.Card5"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="card p-2 rounded-10">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 text-secondary">
                รายการข้อมูลล่าสุด
              </h4>

              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="CalendarIcon"
                    size="18"
                  />
                  <flat-pickr
                    v-model="rangePicker"
                    :config="{ mode: 'range',onClose: CalDate,}"
                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                    placeholder="YYYY-MM-DD"
                  />
                </div>

                <div class="d-flex align-items-center">
                  <b-form-select
                    id="perPageSelect"
                    v-model="Limit"
                    size="sm"
                    inline
                    :options="LimitOptions"
                    @change="GetCountTable()"
                  />
                </div>
              </div>

            </div>

            <div class="row mt-2">
              <div class="col-md-4">
                <div class="d-flex align-items-center justify-content-center">
                  <vue-apex-charts
                    ref="chartdetect"
                    type="pie"
                    height="500"
                    :options="chartOptions"
                    :series="series"
                  />
                </div>
              </div>

              <div class="col-md-8">
                <div class="pl-3 pr-1">
                  <b-table
                    responsive="sm"
                    :fields="fields"
                    :items="items"
                    show-empty
                    class="table table-borderless table-hover table-centered mb-0"
                  >
                    <template #cell(index)="data">
                      {{ (data.index + 1) }}
                    </template>
                    <template #cell(level)="data">
                      <b-button
                        pill
                        :variant="rowClass(data.item).color"
                      >
                        {{ rowClass(data.item).text }}
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable, BFormSelect, BButton, BOverlay,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment-timezone'

export default {
  name: 'Dashboard',
  components: {
    BTable,
    BButton,
    BFormSelect,
    BOverlay,
    flatPickr,
    VueApexCharts,
  },
  data() {
    const today = new Date()
    const DaysLater = new Date()
    DaysLater.setDate(today.getDate() + 1)

    return {
      rangePicker: [
        this.formatDate(today),
        this.formatDate(DaysLater),
      ],
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        legend: {
          position: 'bottom',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
        tooltip: {
          y: {
            formatter(val) {
              return `${val} ครั้ง`
            },
          },
        },
      },
      fields: [
        { key: 'index', label: 'no.', sortable: true },
        { key: 'level', label: 'สถานะ', sortable: true },
        { key: 'tel', label: 'เบอร์โทร', sortable: true },
        { key: 'count', label: 'จำนวน', sortable: true },
      ],
      items: [],
      CardHeader: {
        NotifyLeve2: {
          today: 0,
          all: 0,
        },
        Phone: {
          today: 0,
          all: 0,
        },
        Bank: {
          today: 0,
          all: 0,
        },
        Notify: {
          today: 0,
          all: 0,
        },
      },
      Limit: 10,
      LimitOptions: [10, 25, 50, 100],
      StartDate: moment(today).tz('Asia/Bangkok').format('YYYY-MM-DD'),
      EndDate: moment(DaysLater).tz('Asia/Bangkok').format('YYYY-MM-DD'),
      level_option: [],
      OverLayCard: {
        Card1: false,
        Card2: false,
        Card3: false,
        Card4: false,
        Card5: false,
      },
    }
  },
  async mounted() {
    this.getLevel()
    const [phoneData, bankData, notifyL2Data, notifyData] = await Promise.all([
      this.GetCount('Phone', null, 'Card3'),
      this.GetCount('Bank', null, 'Card4'),
      this.GetCount('Level', 2, 'Card2'),
      this.GetCount('Level', null, 'Card1'),
    ])
    this.GetCountTable()
    this.CardHeader.Phone = phoneData
    this.CardHeader.Bank = bankData
    this.CardHeader.NotifyLeve2 = notifyL2Data
    this.CardHeader.Notify = notifyData
  },
  methods: {
    async getLevel() {
      try {
        const { data: res } = await this.$http.get('/call/showlevel')
        this.level_option = res
      } catch (error) {
        console.log(error)
      }
    },
    rowClass(item) {
      const FindLevel = this.level_option.find(x => x.value === item.level)
      if (FindLevel) {
        return {
          color: FindLevel.color,
          text: FindLevel.text,
        }
      }
      return {
        color: 'secondary',
        text: '-',
      }
    },
    // eslint-disable-next-line no-unused-vars
    async GetCount(Mode, Level, OverLay) {
      try {
        this.OverLayCard[OverLay] = true
        const params = {
          Mode,
          Level,
        }
        const { data: res } = await this.$http.get('/call/dashboard', { params })
        this.OverLayCard[OverLay] = false
        return res
      } catch (error) {
        console.log(error)
        this.OverLayCard[OverLay] = false
        return { today: 0, all: 0 }
      }
    },
    async GetCountTable() {
      try {
        this.OverLayCard.Card5 = true
        const params = {
          StartDate: this.StartDate,
          EndDate: this.EndDate,
          Limit: this.Limit,
        }
        const { data: res } = await this.$http.get('/call/dashboard/table', { params })
        this.items = res
        const series = []
        const labels = []
        res.forEach(element => {
          series.push(element.count)
          labels.push(element.tel)
        })
        this.series = series
        this.chartOptions.labels = labels
        this.$refs.chartdetect.updateOptions(this.chartOptions)
        this.$refs.chartdetect.updateSeries(this.series)
        this.OverLayCard.Card5 = false
      } catch (error) {
        console.log(error)
        this.OverLayCard.Card5 = false
      }
    },
    async CalDate(selectedDates) {
      this.StartDate = moment(selectedDates[0]).tz('Asia/Bangkok').format('YYYY-MM-DD')
      this.EndDate = moment(selectedDates[1]).tz('Asia/Bangkok').format('YYYY-MM-DD')
      await this.GetCountTable()
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    formatDate(date) {
      return date.toISOString().split('T')[0]
    },
  },
}
</script>

<style scoped>
.rounded-10 {
    border-radius: 15px;
}
.text-sz {
    font-family: 'Prompt', sans-serif;
    font-size: 6.4rem;
    font-weight: 600;
    text-align: center;
    margin-top: 50px;
}
.colr-blue {
    color: #2196f3;
}
.colr-red {
    color: #f44336;
}
.colr-green {
    color: #4caf50;
}
.colr-orange {
    color: #ff9800;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
